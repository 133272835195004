import {getUserDeviceHash} from "@fiizy/tracking";

function getDeviceId() {
    return getUserDeviceHash();
}

function getClientInfo() {
    return {
        device: {
            id: getDeviceId() || undefined,
        },
        client: {
            bundle_identifier: app.config.clientBundleIdentifier,
            version: "2.0.0"
        }
    }
}

export default getClientInfo
