import {eventbus, eventBusEvents} from '@fiizy/utils'
import auth from './authClient'

const bus = eventbus(window.eventChannel || 'c432556660722ff93cefb5a932e550b7');
const { logOut, saveCredentials } = eventBusEvents

export let authToken = ''

export async function isUserAuth() {
    if (!(await auth.check())) {
        authToken = null
        return null;
    }

    const accessToken = auth.getAccessToken();

    if (accessToken) {
        bus.emit(saveCredentials, {});
        authToken = accessToken
        return accessToken;
    }

    authToken = null
    return null;
}

function showProfileLink(show) {
    if (show) {
        document.body.classList.add('logged-in')
    } else {
        document.body.classList.remove('logged-in')
    }
}

async function initLogin() {
    showProfileLink(false);

    function goToProfile() {
        window.location = '/profile'
    }

    function goToLogin() {
        window.location = '/login'
    }

    const loginButton = document.querySelector('#login-button');
    if (loginButton) {
        loginButton.addEventListener('click', goToLogin)
    }

    const profileButton = document.querySelector('#profile-button');
    if (profileButton) {
        profileButton.addEventListener('click', goToProfile)
    }

    bus.on(saveCredentials, (payload) => {
        if (payload) {
            showProfileLink(true)
        }
    });
    bus.on(logOut, () => {
        showProfileLink(false)
    })
}


document.addEventListener('DOMContentLoaded', () => {
    initLogin().then(() => {
        isUserAuth();
    });
})
